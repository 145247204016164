import React from "react";
import Artykul from "../components/artykul";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import obrazekArtykulu from "../images/budowa kanalu sprzedazy bezposredniej.jpg";

export const query = graphql`
  query {
    zdjecie1: file(relativePath: { eq: "budowa-kanalu-sprzedazy-posredniej-kampania.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie2: file(relativePath: { eq: "budowa-kanalu-sprzedazy-posredniej-lejek.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie3: file(relativePath: { eq: "budowa-kanalu-sprzedazy-posredniej-konwersja.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie4: file(relativePath: { eq: "budowa-kanalu-sprzedazy-posredniej-przyczyny-odmowy.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie5: file(relativePath: { eq: "budowa-kanalu-sprzedazy-posredniej-aktywnosc.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie6: file(relativePath: { eq: "szansa-sprzedazy.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie7: file(relativePath: { eq: "lejek.PNG" }) {
      childImageSharp {
        fluid(maxWidth: 1436, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    zdjecie8: file(relativePath: { eq: "system-crm-Baner-testuj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 770, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
const BudowaKanałuSprzedażyPośredniej = ({ data }) => {
  return (
    <Artykul
      title="Jak zorganizować proces sprzedaży pośredniej w CRM?"
      articleImage={obrazekArtykulu}
      keywords={["Budowa kanału sprzedaży pośredniej"]}
      articleImageAlt="Budowa kanału sprzedaży pośredniej"
      metaTitle="Jak stworzyć swój własny kanał sprzedaży pośredniej"
      metaDescription="✅ Jak stworzyć swój własny kanał sprzedaży pośrednie? • Wybierz proste i mobilne rozwiązanie • CRM dla firm • QuickCRM - 30 dni za darmo!"
    >
<br /> 
<br />
<h2>Kanał sprzedaży pośredniej w CRM</h2>

 <p>Istnieją różne drogi, dzięki którym możesz dotrzeć do klientów końcowych. Poza najbardziej oczywistym sposobem, jakim jest bezpośrednie ich pozyskiwanie, występują także inne – w tym sprzedaż pośrednia. Polega ona na tym, że podmioty trzecie pośredniczą w przekazywaniu produktu lub usługi do użytkowników końcowych. Budowa kanału sprzedaży pośredniej będzie bardziej efektywna, jeśli zorganizujesz ją w systemie CRM.</p>
 <br />
<h3>Na czym polega ten proces?</h3>
<p>Jego specyfiką jest to, że łączy dostawcę z odbiorcą, poprzez wykorzystanie do tego odrębnego podmiotu – pośrednika. W tym procesie nie pozyskuje się klienta, a właśnie tego typu podmioty. To one mają się zająć pozyskiwaniem klientów końcowych.</p>
<br />
<h3>Kto może zostać Twoim pośrednikiem? </h3>
<p>Wszystko zależy od tego, jaki produkt bądź usługę masz w swojej ofercie. W zależności od nich, każda współpraca będzie wyglądała inaczej.
  <br />
<p>Podczas budowy kanału sprzedaży pośredniej można wyróżnić wiele rodzajów współpracy. Oto dwa przykładowe modele:
  <br />
  <br />
<li> <strong>Współpraca dystrybucyjna</strong>, w której pośrednik rozprowadza prosty, gotowy produkt. Dystrybutor nie musi posiadać unikalnych kompetencji, a zamiast tego powinien dysponować określonymi kanałami sprzedaży. Takimi dystrybutorami mogą być np. hurtownie, pojedyncze sklepy czy sieci handlowe.</li>
<li> <strong>Współpraca partnerska</strong>, która ma na celu rozprowadzanie specjalistycznego produktu bądź usługi. Taka sprzedaż wymaga od pośrednika posiadania odpowiednich kompetencji. Partner musi mieć wtedy merytoryczne przygotowanie, a jego zadaniem może być na przykład: polecanie Twojej firmy, przejmowanie działań handlowych czy realizowanie samych projektów.</li>
</p>
<br />
<h3>Budowa kanału sprzedaży pośredniej – cechy:</h3>
<p>
<li> Głównym zadaniem działu handlowego jest dotarcie do konkretnych podmiotów, które mogą stać się częścią takiego kanału;</li>
<li> Głównym celem w ich działaniach jest podjęcie, a następnie utrzymanie współpracy z pośrednikami;</li>
<li> Budowa kanału sprzedaży pośredniej niesie za sobą wyzwanie, którym jest nakłonienie potencjalnych pośredników do podjęcia współpracy;</li>
</p>
<br />
<h3>Problemy napotykane w budowie kanału sprzedaży pośredniej</h3>
<p>Podczas budowania sieci pośredników możesz napotkać różne trudności. Działania związane z tym procesem mogą być często bardzo czasochłonne i trudne. Oto, jakie przeszkody można spotkać podczas budowy kanału sprzedaży pośredniej:
  <br />
  <br />
<li> <strong>Trudności związane z pokonaniem bariery nieznajomości</strong> – jeśli marka jest nowa na rynku, bądź mało rozpoznawalna, partner mógł o niej nie słyszeć;</li>
<li> <strong>Trudności negocjacyjne</strong> sytuacja, w której partner chce uzyskać jak najwyższą prowizję, marżę czy inne warunki może generować problemy negocjacyjne;</li>
<li> <strong>Problem z weryfikacją know–how</strong> – producent chce, aby produkt lub usługę rekomendowały i sprzedawały osoby, które dobrze go znają – weryfikacja tej wiedzy może być jednak trudna;</li>
<li> <strong>Problem pogodzenia sprzedaży wewnętrznej i pośredniej</strong> – mogą występować konflikty interesów między Twoim działem sprzedaży a partnerami handlowymi. Budowa kanału sprzedaży pośredniej powinna być więc zorganizowana tak, aby unikać tych konfliktów;</li>
<li> <strong>Trudności w pogodzeniu kwestii marketingowych</strong> – warto ustalić, czy partnerzy mogą reklamować Twoją ofertę i w jaki sposób. Zagrożenie polega na tym, że możesz zmniejszyć efektywność swoich reklam i podwyższyć ich koszt, poprzez zwiększenie konkurencji w ramach systemów reklamy internetowej;</li>
<li> <strong>Problem z weryfikowaniem pochodzenia leadu</strong> leadu – partner powinien udowodnić, że to właśnie od niego klient dowiedział się o produkcie. Warto wyznaczyć określone zasady i schematy postępowania, ponieważ wewnętrzna sprzedaż w Twojej firmie mogła już wcześniej kontaktować się z klientem, którego przekazuje partner.</li>
  </p>
  <br />
<h3>Jak skutecznie zbudować kanał sprzedaży pośredniej?</h3>
<p>Szanse na pozyskanie pośredników można pozyskiwać na wiele sposobów. Proces, o którym mowa zaczyna się jednak od momentu, w którym lead trafia już do firmy.
  <br />
Przydatne okaże się powierzenie jednemu z pracowników zadania, którym będzie koordynowanie i skupianie się na budowie kanału pośredników. Sytuacja, w której ta sama osoba będzie zajmować się też sprzedażą wewnętrzną, nie powinna mieć miejsca, ponieważ istnieje ryzyko występowania konfliktów interesów. W związku z tym ważne jest, by zostało to zaprojektowane w taki sposób, aby nie dochodziło do tego typu sytuacji.</p>
<br />
<h3>Jak może wyglądać proces pozyskiwania pośredników?</h3>
<p>Budowa kanału sprzedaży pośredniej zakłada podzielenie sekwencji działań na etapy i określenie na tych etapach celów do zrealizowania. Pozwala to na szybsze mierzenie skuteczności oraz na wyciąganie trafnych wniosków, które mogą się przydać do usprawniania Twojego procesu. 
  <br />
Poniżej przedstawione są przykładowe etapy procesu budowy kanału sprzedaży.</p>
<h4>Uświadomienie</h4>
<p>Nawiązanie kontaktu bądź jego odnowienie oraz pierwsze rozpoznanie. Ustalanie stosunku zależności – to znaczy sprawdzenie, czy obie strony będą mogły osiągnąć korzyści ze współpracy. Sklasyfikowanie możliwych rodzajów współpracy.</p>
<h4>Propozycja</h4>
<p>Przygotowanie konkretnych propozycji współpracy, przekazanie ich i uzyskanie aprobaty po stronie pośrednika. Ustalenie zestawu wszystkich elementów, których będzie potrzebować. Na tym etapie niezwykle ważne jest to, aby dokładnie wskazać to, jakie role ma pełnić każda strona. Pomoże to uniknąć konfliktów w przyszłości.</p>
<h4>Uruchomienie</h4>
<p>Przekazanie materiałów niezbędnych do współpracy. Rozpoczęcie właściwej współpracy. Udzielenie wsparcia rozruchowego.</p>
<br />
<h3>Organizacja procesu budowy kanału sprzedaży w systemie CRM</h3>
<p>Aby zorganizować pozyskiwanie partnerów, warto zastosować system CRM.  Jest to funkcjonalne narzędzie, które służy wspieraniu działań handlowych. Pozwoli na sprawne ich monitorowanie i organizowanie. Dzięki temu można zachować spójność, niezależnie od tego, która osoba będzie wykonywać dane zadanie. </p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Budowa kanału sprzedaży pośredniej - kampania"
          alt="Budowa kanału sprzedaży pośredniej - kampania"
          fluid={data.zdjecie1.childImageSharp.fluid}
        />
        <br />
<p>System porządkuje informacje o procesie oraz zbiera informacje o działaniach pracowników. Ułatwi też pracę managerowi, który będzie w stanie szybko sprawdzić, na jakim etapie są dane szanse oraz co trzeba jeszcze wykonać, aby osiągnąć sukces. Pomaga też dostrzegać elementy oferty (lub samego procesu), które można poprawić, aby podnieść efektywność. </p>
<br />
<h3>Usprawnianie procesu z systemem CRM</h3>
<p>Dzięki automatycznie generowanym raportom, zestawianie ze sobą potrzebnych danych jest o wiele łatwiejsze i mniej czasochłonne niż w przypadku ręcznego zbierania danych.
  <br />
Dostępne w systemie CRM raporty pomagają w dostrzeżeniu słabych punktów oferty firmy czy słabych punktów samego procesu. Prostsze staje się wtedy wprowadzenie działań, mających na celu wsparcie procesu budowy kanału sprzedaży pośredniej.</p>
<br />
<h3>Lejek szans sprzedaży</h3>
<p>Lejek szans to raport, który ułatwia monitorowanie kampanii. Pomoże oszacować sumaryczną liczbę szans i ich wartość na każdym z etapów. Jest przydatny w ocenie prawdopodobieństwa osiągnięcia celów sprzedażowych w danym okresie.</p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto",
            maxWidth: 700
          }}
          title="Budowa kanału sprzedaży pośredniej - lejek"
          alt="Budowa kanału sprzedaży pośredniej - lejek"
          fluid={data.zdjecie2.childImageSharp.fluid}
        />
        <br />
<h3>Konwersja lejka</h3>
<p>Dane dotyczące szans sprzedaży pozwalają raportowi konwersja lejka na bieżąco monitorować szanse na wszystkich etapach. Dzięki niemu wiadomo, jaki procent szans przechodzi z jednego etapu do drugiego. Informuje Cię o tzw. wąskich gardłach, czyli częściach procesu, w których Twoi pracownicy nie radzą sobie najlepiej.</p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Budowa kanału sprzedaży pośredniej - konwersja"
          alt="Budowa kanału sprzedaży pośredniej - konwersja"
          fluid={data.zdjecie3.childImageSharp.fluid}
        />
<h3>Przyczyny utraty</h3>
<p>Raport przyczyny utraty pozwoli Ci wyciągnąć wnioski ze wszystkich powodów, dla których tracisz szanse sprzedaży. Po oznaczeniu szansy jako utraconej, system prosi o podanie przyczyny. Te dane są gromadzone w postaci raportu, który można wykorzystać na wiele sposobów; m.in. do usprawniania swojej oferty czy procesu sprzedaży.</p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto",
            maxWidth: 600
          }}
          title="Budowa kanału sprzedaży pośredniej - przyczyny odmowy"
          alt="Budowa kanału sprzedaży pośredniej - przyczyny odmowy"
          fluid={data.zdjecie4.childImageSharp.fluid}
        />
        <br />
<p><strong>Przykład 1.</strong> Na powyższym wykresie widać, że najczęstszymi przyczynami są zbyt niska marża oraz niejasne warunki. Firma może dzięki temu otrzymać sygnał do zmiany sposobu przedstawienia oferty np. wprowadzić formę przejrzystej infografiki, ukazującej warunki współpracy. Może także zastanowić się nad zmianą sposobu rozliczania pośredników, oferując im np. wyższą marżę, ale tylko jeśli pozyskany przez nich klient utrzyma współpracę przez dłuższy czas.</p>
<br />
<h3>Aktywność handlowców</h3>
<p>Aktywność handlowców pozwala managerowi przeanalizować, jakie działania podjęli pracownicy. Raport ten dostarcza informacji, jakie rodzaje aktywności wykonywali w celu pozyskania pośredników.</p>
<br />

<p><strong>Przykład 2.</strong> Pewna firma, zajmująca się produkcją mebli łazienkowych postanowiła zbudować sieć pośredników. Pomimo wyznaczenia do tego zadania kilku pracowników, tylko jeden z nich odnosił znaczące sukcesy w kontakcie z potencjalnymi współpracownikami. Manager dzięki raportowi aktywność handlowców mógł przeanalizować metody wykorzystywane przez pracowników.</p>
<br />
<Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Budowa kanału sprzedaży pośredniej - aktywność"
          alt="Budowa kanału sprzedaży pośredniej - aktywność"
          fluid={data.zdjecie5.childImageSharp.fluid}
        />
<p>Okazało się, że pracownik odznaczający się najlepszą skutecznością, czyli Marcin, nie kontaktował się telefonicznie z potencjalnymi partnerami na samym początku procesu, a wysyłał im maile. Dzięki temu potencjalny pośrednik miał więcej czasu na zapoznanie się z ofertą.</p>
<br />
Można wywnioskować, że podjął on najlepsze możliwe kroki, dzięki którym jego praca okazała się skuteczna. Wpłynęło to na decyzję zarządu o wprowadzeniu do pierwszego etapu obowiązkowego przesyłania informacji mailowo.</p>
<br />
<h3>Budowanie kanału sprzedaży z systemem CRM</h3>
<p>Rozwiązania CRM znajdą zastosowanie przy rozwijaniu sieci pośredników. Ułatwią monitorowanie szans oraz zaoszczędzą mnóstwo czasu, który można wykorzystać na poszukiwanie kolejnych partnerów. Powyższe przykłady pokazują, jak można zarządzać procesem budowy kanału sprzedaży pośredniej z wykorzystaniem CRM.</p>



<br />

  
<Link to="https://synergiuscrm.pl/synergius-crm-wersja-demo">
        <Img
          style={{
            width: "0 auto",
            margin: "0 auto"
          }}
          title="Testuj System CRM"
          alt="Testuj system CRM"
          fluid={data.zdjecie8.childImageSharp.fluid}
        />
      </Link>
    </Artykul>
  );
};

export default BudowaKanałuSprzedażyPośredniej;
